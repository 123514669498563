<template>
  <b-card class="p-1">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <b-form-group class="w-50 mb-0">
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Wyszukaj po imieniu, nr. telefonu, emailu, adresie" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''"> Wyczyść </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div class="d-flex flex-gap">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="btn"
          @click="openImportModal">
          Importuj z pliku
        </b-button>
        <b-button
          :to="{ name: 'technicians-create' }"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="btn ml-2">
          Dodaj
        </b-button>
      </div>
    </div>
    <b-table
      responsive
      bordered
      striped
      hover
      :items="technicians"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn">
      <template #cell(actions)="row">
        <div class="d-flex flex-column">
          <b-button
            :to="{ name: 'technicians-edit', params: { id: row.item.id } }"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="btn table-btn"
            size="sm">
            Edytuj
          </b-button>
          <b-button class="mt-1 table-btn" variant="danger" size="sm" @click="destroy(row.item)"> Usuń </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import NotificationMixin from '@/mixins/NotificationMixin';

import { all, destroy, importTechnicians } from '@/http/technicians';

import { AddressFormatter } from '@/formatters/AddressFormatter';
import { FullNameFormatter } from '@/formatters/FullNameFormatter';

export default {
  mixins: [NotificationMixin],

  data() {
    return {
      technicians: [],
      filter: '',
      filterOn: [],
      importFile: null,
      fields: [
        {
          key: 'name',
          label: 'Imię i nazwisko',
          sortable: true,
          sortDirection: 'desc',
          formatter: (value, key, item) => {
            return FullNameFormatter(item);
          },
        },
        { key: 'phone', label: 'Nr telefonu', sortable: true, class: 'text-center' },
        { key: 'email', label: 'Email', sortable: true, class: 'text-center' },
        {
          key: 'city',
          label: 'Adres',
          formatter: (value, key, item) => {
            return AddressFormatter(item);
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: 'actions', label: 'Akcje' },
      ],
    };
  },

  async mounted() {
    await this.fetchTechnicians();
  },

  methods: {
    async fetchTechnicians() {
      try {
        const { data } = await all();

        this.technicians = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openImportModal() {
      this.$swal({
        title: 'Importuj techników',
        html: `
        <div class="import-container">
          <input type="file" v-model="importFile" id="file" class="swal2-file" accept=".csv, .xls, .xlsx">
          <div class="checkbox-container">
            <input type="checkbox" id="clear-technicians" class="swal2-checkbox">
            <label for="clear-technicians">Usunąć istniejących techników</label>
          </div>
        </div>`,
        showCancelButton: true,
        confirmButtonText: 'Importuj',
        cancelButtonText: 'Anuluj',
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const file = document.getElementById('file').files[0];
          if (!file) {
            return this.showErrorNotification('Brak pliku', 'Proszę wybrać poprawny plik do importu serwisantów.');
          }

          const overwrite = document.getElementById('clear-technicians').checked;

          const formData = new FormData();
          formData.append('file', file);
          formData.append('clear_technicians', overwrite ? 1 : 0);

          this.uploadTechnicians(formData);
        }
      });
    },
    async uploadTechnicians(data) {
      await importTechnicians(data)
        .then(() => {
          this.showSuccessNotification('Serwisanci zaimportowani', 'Pomyślnie zaimportowano serwisantów.');
        })
        .catch(() => {
          this.showErrorNotification('Błąd', 'Wystąpił błąd podczas importowania serwisantów.');
        });
      this.fetchTechnicians();
    },
    destroy(technician) {
      this.$swal({
        title: 'Jesteś pewny?',
        text: 'Czy chcesz usunąć tego serwisanta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Usuń',
        cancelButtonText: 'Anuluj',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          destroy(technician.id)
            .then(() => {
              this.showSuccessNotification('Serwisant usunięty', 'Pomyślnie usunięto serwisanta.');
              this.fetchTechnicians();
            })
            .catch(() => {
              this.showErrorNotification(
                'Problem z usunięciem danych',
                'Wystąpił błąd podczas usuwania serwisanta. Skontaktuj się ze swoim developerem.',
              );
            });
        }
      });
    },
  },
};
</script>
